import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useStore } from "vuex";
import figureFormatter from "@/services/utils/figureFormatter";
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}
const pdfPrinter = () => {
    const store = useStore();
    const {commaFormat} = figureFormatter();
    const {getHeader, getFooter} = pdfHeaderFooter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, incentivePaymentData, detailsReport = false) => {
        var doc = {
            pageSize: 'A4',
            pageMargins: [10, 100, 10, 80],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(incentivePaymentData,detailsReport),

            styles: {
                header: {
                    fontSize: 24,
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 8,
                font: 'SulaimanLipi'
            },

            info: {
                title: `${detailsReport ? 'Incentive Detailed Statement' : 'Incentive Summary Statement'}`,
            }
        }

        pushIntoTable(doc, incentivePaymentData, detailsReport)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (incentivePaymentData, detailsReport) => {
        return [
            {
                text: `${detailsReport ? 'Incentive Detailed Statement' : 'Incentive Summary Statement'}`,
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                fontSize: 12,
                decoration: 'underline'
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                text: `Referrer: ${incentivePaymentData.data.details.referrer_name}`,
                                alignment: 'left',
                                width: '50%'
                            },
                            {
                                text: `Payment Date: ${incentivePaymentData.data.details.payment_date}`,
                                alignment: 'left',
                                width: '50%'
                            },
                        ]
                    },

                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    body: [],
                    widths: [ '12%', '13%','15%', '10%', '10%', '10%', '10%', '10%', '10%' ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },

            formatSignatureData()
        ]
    }

    const pushIntoTable = (doc, incentivePaymentData, detailsReport) => {
        const tableItems = incentivePaymentData.data.data;
        const tableHeader = getTableHeader();
        doc.content[2].table.body.push(tableHeader);

        tableItems.forEach((item, i) => {
            const rowData = getRowData(item, i);
            doc.content[2].table.body.push(rowData);

            if (!detailsReport){
                return
            }

            const detailsRow = getRowDetails(item.item_details || '');
            doc.content[2].table.body.push(detailsRow);
        });

        const getLastRow = getTotal(incentivePaymentData.data.details)
        doc.content[2].table.body.push(getLastRow);
    }

    const getTableHeader = () => {
        const tableHeader = [
            {text: 'Invoice Date'},
            {text: 'Invoice No'},
            {text: 'Patient Name (ID)'},
            {text: 'Total Bill', alignment: 'right'},
            {text: 'Total Discount', alignment: 'right'},
            {text: 'Net Bill', alignment: 'right'},
            {text: 'Total Incentive', alignment: 'right'},
            {text: 'Referrer Discount', alignment: 'right'},
            {text: 'Net Incentive', alignment: 'right'},
        ]

        return tableHeader
    }

    const getRowData = (item, i) => {

        const tableRow = [
            {text: item.sale_master.date},
            {text: item.sale_master.bill_number},
            {text: item.sale_master.contact_profile.full_name + '(' + item.sale_master.contact_profile.patient_id + ')' },
            {text: commaFormat(item.total_invoice), alignment: 'right'},
            {text: commaFormat(item.total_discount), alignment: 'right'},
            {text: commaFormat(item.net_bill), alignment: 'right'},
            {text: commaFormat(item.total_incentive), alignment: 'right'},
            {text: commaFormat(item.referrer_discount), alignment: 'right'},
            {text: commaFormat(item.net_incentive), alignment: 'right'},
     ]

        return tableRow
    }

    const formatSignatureData = () => {

        let signatures = [ 'Prepared By', 'Reviewed By', 'Approved By' ];

        return {
            alignment: 'justify',
            absolutePosition: { x: 60, y: 740 },
            columns: signatures.map(getSingleSignature)
        }
    }

    const getSingleSignature = (title) => {
        return {
            width: '43%',
            stack: [
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 58,
                            y2: 0,
                            lineWidth: 1
                        }
                    ]
                },
                {
                    margin: [ 0, 5, 0, 0 ],
                    text: `${title} `,
                    color: 'black',
                    bold: true,
                    alignment: 'left',
                }
            ]
        }
    }

    const getRowDetails = (itemDetails) => {
        return [
            {
                text: itemDetails,
                colSpan: 9
            },
            {}, {}, {}, {}, {}, {}, {}, {}
        ];
    };

    const getTotal = (totalValue) => {

        const lastRow = [
            {
                text: 'Total',
                alignment: 'right',
                colSpan: 3
            },

            '','',

            {
                text: totalValue.total_invoice,
                alignment: 'right'
            },

            {
                text: totalValue.total_discount,
                alignment: 'right'
            },

            {
                text: totalValue.net_bill,
                alignment: 'right'
            },

            {
                text: totalValue.total_incentive,
                alignment: 'right'
            },

            {
                text: totalValue.referrer_discount,
                alignment: 'right'
            },

            {
                text: totalValue.net_incentive,
                alignment: 'right'
            },
        ]

        return lastRow
    }

    return {
        exportToPDF
    }

}

export default pdfPrinter;