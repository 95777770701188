<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                title="Incentive Payment"
                btnTitle="Add New"
                :showAddNew="true"
                @onClickAddNewButton="onClickAddNewButton"
            />
        </div>
        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickToGo"
                class="tds-vds-query-setter"
            >
              <div class="col-12 col-sm-6 col-md-3 col-lg-2">
                <label
                    for="colFormLabel"
                    class="col-form-label"
                >
                  Referrer
                </label>
                <AsyncSelect
                    placeholder="Select Referrer"
                    v-model="referrer"
                    :api-service="fetchContactProfiles"
                    :additional-query="{ type: 'agent' }"
                    :format-label="formatPatientLabel"
                    :reduce="referrer => referrer.id"
                />
              </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
          <div class="card p-2">
            <div class="table-responsive"  style="min-height: 400px">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th class="text-center">Referrer</th>
                  <th class="text-center">Incentive Payment Date</th>
                  <th class="text-center">Total Incentive</th>
                  <th class="text-center">Referrer-Discount</th>
                  <th class="text-center">Net Incentive</th>
                  <th class="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in tableItems" :key="i">
                  <td class="text-center">{{ item.contact_profile_name }} ({{ item.contact_profile_serial_no }})</td>
                  <td class="text-center">{{ item.date }}</td>
                  <td class="text-center">{{ item.total_incentive_amount }}</td>
                  <td class="text-center">{{ item.total_referrer_discount }}</td>
                  <td class="text-center">{{ item.total_net_incentive }}</td>
                 <td>
                      <div class="btn-group">
                        <button type="button" class="btn   dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="fa-solid fa-ellipsis-vertical"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a @click="goToPrint(item)" class="dropdown-item" href="#">Print Summary</a>
                          <a @click="goToPrint(item, true)" class="dropdown-item" href="#">Print Detailed Statement</a>
                        </div>
                      </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <p v-if="tableItems.length < 0" class="text-center mt-5">No data</p>
            </div>
          </div>
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>

    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import {inject, onMounted, reactive, ref} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import { computed } from 'vue';
import handleInventory from "@/services/modules/inventory";
import handleHospital from "@/services/modules/hospital";
import handleContact from '@/services/modules/contact'
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import incentivePrinter from '@/services/utils/pdf/incentivePaymentSummary';
import handleCompany from "@/services/modules/company";

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const referrer = ref(null)
const tableItems = ref([]);
let companyInfo = reactive({})
const incentivePaymentData = ref({});
const status = ref([]);
const params = ref({
    offset: 20,
});
let saveButtonLoader = ref(false)

const token = computed(() => localStorage.getItem('token'));

const { fetchIncentivePayment, fetchIncentivePaymentSummary } = handleHospital()
const {fetchContactProfiles} = handleContact()
const { formatPatientLabel } = useAsyncDropdownHelper()
const { fetchHome } = handleInventory()
const { exportToPDF } = incentivePrinter();
const { fetchCompanyInfo } = handleCompany();

const onClickToGo = () => {
  getIncentivePaymentList();
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const getIncentivePaymentList = async () => {
  const query = getQuery();
  loading.value = true
  tableItems.value = []

  await fetchIncentivePayment(query).then(res => {
    if (!res.status){
      showError(res.message)
      return
    }

    tableItems.value = res.data.data;
    setPagination(res.data)
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const setCompanyInfo = async () => {
  await fetchCompanyInfo(route.params.companyId).then((res) => {
    companyInfo = res.data;
  }).catch((error) => {
    console.log(error);
  });
}

const goToPrint = async (item, detailsReport = false) => {
  let query = `?company_id=${route.params.companyId}`;
  query += '&referrer_id=' + item.contact_profile_id;
  query += '&date=' + item.date;

  await fetchIncentivePaymentSummary(query).then((res) => {
    if (!res.status) {
      showError(res.message)
      return;
    }
    incentivePaymentData.value = res
  }).then(() => {
    exportToPDF(companyInfo, incentivePaymentData.value, detailsReport)
  }).catch((err) => {
    console.log("Something went wrong");
  });
}

const getQuery = () => {
  let query = `?company_id=${route.params.companyId}`;
  if (route.query.page) query += '&page=' + route.query.page;
  if (referrer.value) query += '&referrer_id=' + referrer.value;
  if (route.query.start) query += '&start_date=' + route.query.start;
  if (route.query.end) query += '&end_date=' + route.query.end;
  query += '&offset=' + params.value.offset
  return query;
}

function onClickAddNewButton() {
  router.push({name: `incentive-payment-add`, params: route.params, query: route.query})
}

const onPageChange = async (page) => {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getIncentivePaymentList()
}

onMounted(() => {
  setCompanyInfo()
})

</script>
